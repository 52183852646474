.portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 800px;
  margin: 50px auto;
  margin-top: 200px;
  border: 2px solid rgba(255, 255, 255, 0.5); /* Borde semi-transparente */
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); /* Brillo alrededor del icono */
  padding: 40px;
  border-radius: 2%;
  background-color: black;

}

.title2 {
  color: #F0AD4E;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 30px;
}
.skill {
  position: relative; /* Establecemos la posición relativa para poder posicionar el cuadro de texto dentro */
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
  border: 2px solid transparent; /* Cambiamos el borde a transparente */
  transition: transform 0.3s; /* Agregamos una transición suave */
  padding: 15px;
}

/* Al pasar el cursor sobre la habilidad, se agranda un poco */
.skill:hover {
  transform: scale(1.1); /* Aumentamos el tamaño al 110% */
  
}
.skill:hover::after {
  content: attr(label); /* Esta línea debe cambiarse a 'content: datalabel;' */
  position: absolute;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  bottom: 120%; /* Ajustamos la posición del cuadro de texto */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Hacemos visible el cuadro de texto */
  transition: opacity 0.3s; /* Agregamos una transición suave */
}


/* Ocultamos el cuadro de texto por defecto */
.skill::after {
  content: " "; /* Agregamos un espacio en blanco */
  position: absolute;
  background: black;
  color: white;
  padding: 5px;
  border-radius: 5px;
  bottom: 120%; /* Ajustamos la posición del cuadro de texto */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0; /* Ocultamos el cuadro de texto por defecto */
  transition: opacity 0.3s; /* Agregamos una transición suave */
}




/* Agregamos bordes de colores correspondientes a los iconos */

.skill:nth-child(1) {
  border: 2px solid #111111; 
}

.skill:nth-child(2) {
  border: 2px solid #F05032; /* HTML5 */
}

.skill:nth-child(3) {
  border: 2px solid #190ae4;   /* CSS3 */
}

.skill:nth-child(4) {
  border: 2px solid #c5c332; /* JavaScript */
}

.skill:nth-child(5) {
  border: 2px solid #61DAFB; /* React */
}

.skill:nth-child(6) {
  border: 2px solid #3776AB; /* Python */
}

.skill:nth-child(7) {
  border: 2px solid #68A063; /* Node.js */  
}

.skill:nth-child(8) {
  border: 2px solid #F05032; /* Git */
}

.skill:nth-child(9) {
  border: 2px solid #563D7C; /* Bootstrap */
}

.skill:nth-child(10) {
  border: 2px solid #21759B;  /*SQL */
}

.skill:nth-child(11) {
  border: 2px solid #21759B; /* WordPress */
}


.skill span {
  font-size: 14px;
  text-align: center;
  margin-left: 5px;
}
/* Estilos para dispositivos con un ancho mínimo de 769px (pantallas grandes) */
@media screen and (max-width: 769px) {
  .portfolio {
    width: 300px;; /* Muestra el menú superior en pantallas grandes */
    margin-top: 10px;
    padding: 10px;
    margin-bottom: 800px;
  
  }
  
  .skill {
    margin-top: 20px;
    width: 80px; /* Oculta el menú móvil en pantallas grandes */
    height: 90px;
  }
  .title2 {
    color: white;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 5px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1366px) {
  .portfolio {
    margin-top: 30px;
  }
}

