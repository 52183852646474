.homepage-image {
  border-radius: 20%; /* Hace que la imagen sea circular */
  width: 350px; /* Ajusta el tamaño de la imagen */
  margin-top: 210px; /* Margen superior por defecto */
  transition: transform 1s ease-out; /* Agrega transición para el cambio de rotación */
}

.coin-rotation {
  animation: rotate-coin 2s linear infinite;
}

.rotate-zero {
  transition: transform 0.5s ease-in; /* Transición al restablecer la rotación */
}

@keyframes rotate-coin {
  0% { transform: rotate(0deg); }
  100% { transform: rotateY(320deg); }
}
.homepage-image:hover,
.homepage-image:focus {
  transform: scale(1.05) !important; /* Aumenta el tamaño de la imagen en un 10% */
  box-shadow: 0 0 15px rgb(214, 148, 56); /* Agrega un resplandor al borde */
}


@media screen and (max-width: 769px) {
  .homepage-image {
    margin-top: 70px; /* Muestra el menú superior en pantallas grandes */
    width: 250px;
  }
  .homepage-image:active {
    transform: scale(1.05); /* Aumenta el tamaño de la imagen en un 10% */
    box-shadow: 0 0 15px rgba(214,148,56); /* Agrega un resplandor al borde */
  }
}

/* Añade las clases para controlar la velocidad de la animación */
.fast {
  animation-duration: 0.5s; /* Velocidad más rápida */
}

.faster {
  animation-duration: 0.3s; /* Velocidad aún más rápida */
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
  .homepage-image{
      margin-top: 170px;
    }
  }