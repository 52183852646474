@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

footer .body {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  min-height: 100vh;
  background: #333;
}

footer {
  position: relative;
  width: 100%;
  background: #FFAB35;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

footer .social_icon,
footer .menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

footer .social_icon li,
footer .menu li {
  list-style: none;
}

footer .social_icon li a {
  font-size: 2em;
  color: white;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
}

footer .social_icon li a:hover {
  transform: translateY(-15px);
  color: black;
}

footer .menu li a {
  font-size: 1.2em;
  color: #ccc;
  margin: 0 10px;
  display: inline-block;
  transition: 0.5s;
  text-decoration: none;
  opacity: 0.75;
}

footer .menu li a:hover {
  opacity: 1;
  color: #fff;
}

p {
  color: #eee;
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.1em;
}

/* Wave and animation */
footer .wave {
  position: absolute;
  top: -100px;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(/src/Images/Image.png);
  background-size: 1000px 100px;
}

footer .wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}

footer .wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}

footer .wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 0;
  animation: animateWave 3s linear infinite;
}

footer .wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}
@media screen and (max-width: 769px) {
  footer {
    height: 250px;

}
  }