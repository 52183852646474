.button-container {
  position: fixed;
  top: 10px; /* Ajusta la distancia superior según tus necesidades */
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content; /* Ajusta el ancho según el contenido */
  background-color: transparent;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent; /* Establece un borde transparente por defecto */
  border-radius: 30px;
  z-index: 9999;
  transition: height 0.3s ease; /* Transición para el cambio de tamaño */
}

.button-container.large {
  height: 50px; /* Altura más grande cuando se desplaza */
  border-color: #56B4BE; /* Cambia el color del borde cuando se desplaza */
  transform: translateY(0); /* Restablece la posición vertical */
}


.button-container button {
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 10px;
  color: rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button-container button:hover {
  color: white;
  background-color: transparent;
}

.page1, .page2, .page3, .page4 {
  height: 100vh; 
  background-size: cover; /* Ajusta el tamaño de la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen en el contenedor */
  padding-top: 10px;
}

.page1 {
 /* background-image: url('../Images//Proyects.jpg'); */
 background-color: black;
 
}  

.page2 {
  /* background-image: url('../Images//background2.jpg'); */
  background-color: black;
 
}

.page3 {
/* background-image: url('../Images//Proyects.jpg'); */
background-color: black; 
  
}

.page4 {
 /* background-image: url('../Images//Contact.jpg'); */
 background-color: black;
}
.thome {
  margin-left: 50%;
  font-size: 1px;
  color: transparent
}

.tskills {
  text-align: center;
  margin-top: 200px;
  color: white;
  animation: lights 5s 750ms linear infinite;
  font-size: 40px;
}


.tproyects {
  text-align: center;
  padding-top: 200px;
  color: white;
  animation: lights 5s 750ms linear infinite;
  font-size: 40px;
}

.tcontact {
  color: white;
  text-align: center;
  margin-bottom: 200px;
  margin-top: 200px;
  animation: lights 5s 750ms linear infinite;
  font-size: 40px;
}
@media screen and (max-width: 768px) {
  .page1 {
    height: 750px; 
    margin-bottom: -10px;
    padding-top: 80px;
  }
  .page2{
    height: 900px;
  }
  .tskills{
    margin-top: 80px;
  }
  .page3{
    height: 2050px;
  }
  .page4{
    
    height: 900px;
  }

  .tcontact {
    margin-top: 200px;
    margin-bottom: 60px;
    
    }
}



@media screen and (min-width: 768px) and (max-width: 1366px) {
.tskills {
    text-align: center;
    margin-top: 200px;
    color: white;
  }
.page2{
    height: 900px;
  }
  .page1{
    height: 1000px;
  }
  .page3{
    height: 1000px;
  }
  .page4{
    height: 1000px;
  }
  .tproyects {
    text-align: center;
    margin-bottom: 200px;
    padding-top: 60px;
  }
  .tcontact {
    margin-top: 100px;
    margin-bottom: 60px;
    
    }

  }