.contact-me-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 300px;
    color: white;
    margin-left: 15%;
    margin-right: 15%;
  }
  
  .contact-form {
    width: 30%;
  }
  
  .contact-form h2 {
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  textarea {
    resize: vertical;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #F0AD4E;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  
  .code-example {
    width: 47%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    background-color: #f9f9f9;
    color: black;
  }

  .send {
    margin-right: 5px;
    font-size: 14px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease; 
    background-color: #F0AD4E;
  }
  
  .send:hover {
    transform: translateY(-3px); 
    background-color: #337AB7; 
    color: white; 
  }
  .alert {
    color: greenyellow;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  
  @media screen and (max-width: 769px) {
      .code-example {
        display: none;
      }
      .contact-me-container {
        margin-left: 0px;
        margin-right: 0px;
        justify-content: center;

       }
        
      .contact-form {
         width: auto;
  }
  input[type="text"],
  input[type="email"],
  textarea {
    width:90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  button {
    padding: 10px 17px;
  }
    }