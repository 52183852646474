/* Estilos para dispositivos con un ancho máximo de 768px (pantallas pequeñas) */
@media screen and (max-width: 768px) {
  .button-container {
    display: none; /* Oculta el menú superior en dispositivos móviles */
  }
  
  .mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: transparent; /* Color de fondo del menú móvil */
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 9999;
    margin: auto;
   
  }
  
  .mobile-menu button {
    background: none;
    border: none;
    font-size: 20px;
    color: #333; /* Color del texto del menú móvil */
    cursor: pointer;
    padding: 10px auto;
    
  }
}

/* Estilos para dispositivos con un ancho mínimo de 769px (pantallas grandes) */
@media screen and (min-width: 768px) {
  .button-container {
    display: flex; /* Muestra el menú superior en pantallas grandes */
  }
  
  .mobile-menu {
    display: none; /* Oculta el menú móvil en pantallas grandes */
  }
}

