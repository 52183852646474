/* MyCarousel.css */
.my-carousel {
  border-radius: 20%; /* Bordes redondeados */
  height: 500px;
  width: 60%;
  margin-top: 200px;
  margin-bottom: 360px;
  align-items: center;
  display: flex;
  margin-right: auto;
  margin-left: auto;
}

.my-carousel2 {
  border-radius: 20%; /* Bordes redondeados */
  height: 600px;
  width: 60%;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: auto;
  margin-top: auto;
}

a {
  color: white;
}
.icono {
  height: 200px;
  
}
.icono2 {
  height: 200px;
}
.icono-link {
  display: inline-block; /* Para que el enlace ocupe el espacio del contenido */
  margin: 1px; /* Ajusta el margen para aumentar el área clickeable */
  
}

/* Nuevos estilos para la división de información e imagen */
.proyect-container {
  display: flex;  
  background-color: transparent;
  
  
}

.proyect-info {
  flex: 1;
  padding: 20px;
  color: white;
  justify-content: center;
  
}

.img1 {
 max-width: 50%;
 max-height: 50%;
 height: 50% !important;
 margin-top: 200px;


}
.img2 {
  max-width: 60%;
  height: 350px;


}
.icoskill {
  width: 60px;
  display: flex ;
  margin-left: 100px;
  
}
.iconew{
  width: 80px;
  height: 80px;
  display: flex ;
  
}
.wordpress{
  margin-left: 200px;
}


/* Estilos para pantallas más pequeñas, como teléfonos */
@media screen and (max-width: 767px) {
  .proyect-container {
    flex-direction: column; /* Cambia la dirección del flexbox a columna */
    text-align: center; /* Centra el texto */
  }
  .carousel .carousel-slider .control-dots .dot {
    width: 8px; /* Ancho reducido de los puntos en móvil */
    height: 8px; /* Alto reducido de los puntos en móvil */
    margin: 0 3px; /* Espacio reducido entre los puntos en móvil */
  }

  .proyect-info {
    order: 2; /* Cambia el orden para que el texto aparezca después de la imagen */
    padding: 10px; /* Ajusta el padding del texto */
    color: white;
  
  }

  .img1 {
    order: 1; /* Cambia el orden para que la imagen aparezca antes del texto */
    margin-bottom: 10px; /* Añade un margen inferior a la imagen */
    max-width: 100%;
  }
  .img2 {
    max-width: 100%;
    height: 200px;
    margin-top: 20px;
  }
  
  .icono-link {
   height: 160px;
  }
  .my-carousel {
  width: auto;
  height: auto;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;
  }
  .proyect-info {
    flex: 1;
    padding: 20px;
    color: white;
    
  }
  .icono {
    height: 120px;
  }
  .icoskill {
    margin-left: 0px;
    
  }
}
@media screen and (min-width: 768px) and (max-width: 1366px) {

.img2 {
  max-width: 100%;
  height: 350px;
  }
  .wordpress{
    margin-left: 70px;
  }
  .icono {
    height: 120px;
  }
  .icoskill {
    margin-left: 0px;
    
  }
}

